import React from "react";
import Layout from "../components/layout";
import AppleBeach from "../images/obras/Apple Beach.jpeg";
import Jacques from "../images/obras/Jacques.jpeg";
import ObrasBanner from "../images/obras/Obras.jpg";
import PalmCoast from "../images/obras/Palm Coast.jpeg";
import Parceiros from "../components/Parceiros";
import SEO from "../components/seo";

function Obras() {
  return (
    <Layout>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Obras executadas"
      />

      <section
        style={{
          height: "300px",
          backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.3)), url(${ObrasBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <h1 className="font-bold text-white ml-8 mt-24 uppercase base-title-golden">
          Obras executadas
        </h1>
      </section>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 my-20 mx-6 md:mx-20 xl:mx-48">
        <div>
          <h2 className="font-bold text-center mb-2">PALM COAST</h2>
          <img src={PalmCoast} className="img-zoom" />
        </div>
        <div>
          <h2 className="font-bold text-center mb-2">APPLE BEACH</h2>
          <img src={AppleBeach} className="img-zoom" />
        </div>
      </div>
      <div className="mb-20 mx-6">
        <h2 className="font-bold text-center mb-2 justify-center">
          JACQUES BEACH
        </h2>
        <img src={Jacques} className="img-zoom md:w-3/5 mx-auto" />
      </div>
      <Parceiros />
    </Layout>
  );
}

export default Obras;
